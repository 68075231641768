<template>
  <div class="home">
    <nav-header v-if="shouldShow"></nav-header>
    <router-view></router-view>
    <nav-footer v-if="shouldShow"></nav-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavHeader from "@/components/NavHeader";
import NavFooter from "@/components/NavFooter";

export default {
  name: "Layout",
  components: {
    NavHeader,
    NavFooter,
  },
  computed: {
    shouldShow() {
      console.log(this.$route.path);
      return this.$route.path !== "/landing-page";
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/layout.scss";
</style>
