<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'App',
  created() {
    // this.loadJS('https://cdn.jsdelivr.net/npm/element-ui@2.15.13/lib/index.min.js');
  },
  methods: {
    loadJS(url) {
      return new Promise((resolve) => {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        // IE
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState == 'loaded'
              || script.readyState == 'complete') {
              script.onreadystatechange = null;
              resolve();
            }
          };
        } else {
          // 其他浏览器
          script.onload = function () {
            resolve();
          };
        }
        document.getElementsByTagName('head')[0].appendChild(script);
      })
    }
  }
}
</script>

<style lang="scss">
@import "./assets/scss/reset.scss";
@import "./assets/scss/layout.scss";
// @import "https://cdn.jsdelivr.net/npm/element-ui@2.15.13/lib/theme-chalk/index.min.css";
</style>
